import { style } from '@do/walrus';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MarketPlaceTopHat } from '../../graphql/generated-types/getTopHats';

const TopHatWrapper = styled.div`
  background-color: ${style.colors.grey.darkest};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftMessage = styled.div<{ isAnimateOut: boolean }>`
  font-weight: bold;
  color: white;
  padding-left: ${style.vars.space[10]};
  transition: transform 1s ease-in-out;
  transform: translateY(${(props) => (props.isAnimateOut ? '-150%' : '0')});
`;

const RightLinks = styled.div`
  gap: 10px;
  color: white;
  padding-right: ${style.vars.space[10]};
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  margin-right: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledMessageLink = styled.a`
  color: white;
  text-decoration: none;
  margin-right: 10px;
`;

interface TopHatProps {
  topHats: MarketPlaceTopHat[];
}

const extractTopHatData = (topHat: MarketPlaceTopHat) => {
  const text = topHat?.top_hat_text[0]?.text;
  const linkUrl = topHat?.link?.url;
  return { text, linkUrl };
};

const TopHat: React.FC<TopHatProps> = ({ topHats }) => {
  const baseUrl = 'https://digitalocean.com';
  const [currentTopHatIndex, setCurrentTopHatIndex] = useState(0);
  const [isAnimateOut, setAnimateOut] = useState(false);

  const filteredTopHats = topHats.filter((topHat) => topHat.show === true);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimateOut(true); // Animate out the LeftMessage
      setTimeout(() => {
        setCurrentTopHatIndex(
          (prevIndex) => (prevIndex + 1) % filteredTopHats.length
        );
        setAnimateOut(false); // Animate in the next LeftMessage
      }, 1000); // Wait for 1 second before updating the currentTopHatIndex and animating in
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [topHats]);

  if (!filteredTopHats || filteredTopHats.length === 0) return null;

  const currentTopHat = filteredTopHats[currentTopHatIndex];
  const { text, linkUrl } = extractTopHatData(currentTopHat);

  return (
    <TopHatWrapper className="top-hat" data-testid="top-hat">
      <LeftMessage isAnimateOut={isAnimateOut}>
        <StyledMessageLink href={linkUrl} data-testid="top-hat-link">
          {text}
        </StyledMessageLink>
      </LeftMessage>
      <RightLinks>
        <StyledLink data-testid="nav-links" href={`${baseUrl}/blog`}>
          Blog
        </StyledLink>
        <StyledLink data-testid="nav-links" href={`${baseUrl}/docs`}>
          Docs
        </StyledLink>
        <StyledLink data-testid="nav-links" href={`${baseUrl}/support`}>
          Get Support
        </StyledLink>
        <StyledLink data-testid="nav-links" href={`${baseUrl}/contact-sales`}>
          Contact Sales
        </StyledLink>
      </RightLinks>
    </TopHatWrapper>
  );
};

export default TopHat;
