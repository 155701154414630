import { useQuery } from '@do/seashell/graphql';
import { style } from '@do/walrus';
import _get from 'lodash/get';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MetaTags from '../components/MetaTags';
import PreviewAlert from '../components/PreviewAlert';
import TopHat from '../components/TopHats/TopHat';
import { MarketplaceUserContextProvider } from '../context/MarketplaceUserContext';
import CategoriesQuery from '../graphql/queries/categories.gql';
import TopHatsQuery from '../graphql/queries/getTopHats.gql';
import SolutionsQuery from '../graphql/queries/solutions.gql';
import { CategoryProps, PrismicRef } from '../interfaces/Prismic';
import { PageContainer } from '../styles/components';
import { NavigationItem } from '../types';
import {
  SWITCH_TO_NAVIGATION_ITEMS,
  VENDOR_NAVIGATION_ITEMS
} from '../utils/constants';
import { slugify } from '../utils/slug-helpers';

const GlobalStyle = createGlobalStyle<{ isInIframe?: boolean }>`
  html, body {
    background: ${({ isInIframe }) =>
      isInIframe ? style.colors.white : style.colors.shadesOfGrey['1']};
    height: 100%;
    box-sizing: border-box;
  }

  p {
    color: ${({ isInIframe }) =>
      isInIframe ? style.colors.grey.darkest : style.colors.shadesOfGrey['8']};
  }

  body {
    display: flex;
    flex-direction: column;

    #__next {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }
  }`;

const Content = styled.main`
  flex: 1 0 auto;
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  preview?: boolean;
  prismicRef?: string;
  isInIframe?: boolean;
  noIndex?: boolean;
}

function getCategoryNavItems(categories): NavigationItem[] {
  return categories.map((category) => ({
    title: category.category || 'All Solutions',
    subtitle: _get(category, 'nav_description[0].text'),
    link: category ? `/category/${slugify(category.category)}` : ''
  }));
}

function getSolutionNavItems(solutions): NavigationItem[] {
  return solutions.map((solution) => ({
    title: _get(solution, 'title[0].text'),
    subtitle: _get(solution, 'hero_title[0].text'),
    link: `/solutions/${_get(solution, 'uid')}`
  }));
}

const MainContent: React.FC<{
  categoryItems: NavigationItem[];
  useCaseItems: NavigationItem[];
  isInIframe: boolean;
}> = ({ categoryItems, useCaseItems, isInIframe, children }) => {
  const finalCategoryItems = [...categoryItems];

  return (
    <Content>
      {!isInIframe && (
        <Header
          categoryItems={finalCategoryItems}
          useCaseItems={useCaseItems}
          vendorItems={VENDOR_NAVIGATION_ITEMS}
          switchToItems={SWITCH_TO_NAVIGATION_ITEMS}
        />
      )}
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>)
      )}
    </Content>
  );
};

function Main({
  children,
  preview,
  prismicRef,
  isInIframe = false,
  noIndex = false
}: Props) {
  const { data } = useQuery<CategoryProps, PrismicRef>(CategoriesQuery, {
    variables: {
      ref: prismicRef
    }
  });

  const { data: solutionsData } = useQuery(SolutionsQuery, {
    variables: {
      ref: prismicRef
    }
  });

  const { data: tophatsData } = useQuery(TopHatsQuery);

  if (!data || !solutionsData) {
    return null;
  }

  const categories = _get(data, 'GetCatalogCategoriesPage.categories');
  const solutions = _get(solutionsData, 'GetCatalogSolutionsPages.solutions');
  const tophats = _get(tophatsData, 'GetMarketplaceTopHats');

  const categoryItems = getCategoryNavItems(categories);
  const useCaseItems = getSolutionNavItems(solutions);

  return (
    <>
      <MarketplaceUserContextProvider>
        <GlobalStyle isInIframe={isInIframe} />
        <MetaTags noIndex={noIndex} />
        {tophatsData && <TopHat topHats={tophats} />}
        <MainContent
          categoryItems={categoryItems}
          useCaseItems={useCaseItems}
          isInIframe={isInIframe}
        >
          {children}
        </MainContent>
        {!isInIframe && (
          <Footer
            categoryItems={categoryItems}
            useCaseItems={useCaseItems}
            vendorItems={VENDOR_NAVIGATION_ITEMS}
            switchToItems={SWITCH_TO_NAVIGATION_ITEMS}
          />
        )}
        <PreviewAlert preview={preview} />
      </MarketplaceUserContextProvider>
    </>
  );
}

Main.FullPageWidth = ({ children }) => <>{children}</>;
Main.Page = ({ children, className }: Props) => (
  <PageContainer className={className}>{children}</PageContainer>
);

export default Main;
