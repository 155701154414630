import React, { useRef, useEffect, useState, createContext } from 'react';
import styled from 'styled-components';
import copy from '../../components/copy';

type User = {
  id: string;
  features: string[];
};

interface ContextState {
  user: User | null;
  isUnauthorized: boolean;
  hasRememberMeCookie: boolean;
}

export const IFRAME_ORIGIN_URL = 'https://cloud.digitalocean.com';
export const POST_MESSAGE_APP_URL = `${IFRAME_ORIGIN_URL}/post-message-app`;

const HiddenIframe = styled.iframe`
  display: none;
`;

export const MarketplaceUserContext = createContext<ContextState>(null);

export const MarketplaceUserContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] = useState<ContextState>({
    user: null,
    isUnauthorized: false,
    hasRememberMeCookie: false
  });

  const iframeRef = useRef<HTMLIFrameElement>();

  useEffect(() => {
    const messageEventListener = (event: MessageEvent) => {
      if (event.origin !== IFRAME_ORIGIN_URL) {
        return;
      }

      const { user, isUnauthorized, hasRememberMeCookie } = event.data;

      if (!user) {
        return;
      }

      setState({
        user,
        isUnauthorized,
        hasRememberMeCookie
      });
    };

    window.addEventListener('message', messageEventListener);

    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  });

  return (
    <MarketplaceUserContext.Provider value={state}>
      {children}

      <HiddenIframe
        ref={iframeRef}
        src={POST_MESSAGE_APP_URL}
        title={copy.postMessageAppListener.title}
        sandbox="allow-scripts allow-same-origin allow-top-navigation"
      />
    </MarketplaceUserContext.Provider>
  );
};
