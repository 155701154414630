import { style } from '@do/walrus';
import styled from 'styled-components';

export const IconButtonContainer = styled.button`
  align-items: center;
  appearance: none;
  background: ${style.colors.white};
  border: 0 none;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
`;

export const HeaderButtonContainer = styled(IconButtonContainer)`
  box-sizing: content-box;
  height: ${style.vars.space['4']};
  padding: ${style.vars.space['4']};
  position: absolute;
  top: 0;
`;

export const CloseButtonContainer = styled(HeaderButtonContainer)`
  z-index: ${style.vars.zIndex.raised};
`;
