import {
  Avatar,
  Badge,
  Box,
  ListItem as DefaultListItem,
  Icon,
  MenuItem as MenuItemProps,
  NotificationsBadge,
  style,
  useToggle
} from '@do/walrus';
import React from 'react';
import styled from 'styled-components';
import { HeaderButtonContainer } from './styled-components';

type StyledInlineAlertIconProps = React.ComponentProps<typeof Icon>;

const StyledInlineAlertIcon: React.FC<StyledInlineAlertIconProps> = (props) => {
  return (
    <Box mr={style.vars.space['1']} mb="2px">
      <Icon {...props} />
    </Box>
  );
};

const ListItem = styled(DefaultListItem)`
  padding: 0;
  padding-left: 1.5rem;
`;

const MenuItemsContainer = styled.div<MenuContainerProps>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  transition: transform ${style.vars.transitionSpeed.base};

  left: 100%;
  z-index: 1;

  ${(props) => props.isOpen && 'transform: translate(-100%);'}
`;

const MenuHeader = styled.div`
  position: relative;
  background: ${style.colors.white};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 72px;
  flex-shrink: 0;
  flex-grow: 0;
  border-bottom: 1px solid ${style.colors.grey.light};
`;

const MenuItemsWrapper = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: ${style.colors.white};
  z-index: 1;
`;

const MenuItems = styled.ul<MenuPanelProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const MenuHeading = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 1.5rem; /* @todo: make var */
  font-weight: ${style.vars.fontWeight.bold};
  line-height: 1;
  margin: 0 auto;
  text-align: center;
`;

const PreviousButton = styled(HeaderButtonContainer)`
  left: 0;
  top: -1px;
`;

const IconButton = styled(Icon)`
  color: ${style.colors.grey.primary};
  height: 1rem;
  line-height: 1rem;
  width: 1rem;
`;

const StyledNotificationsBadge = styled(NotificationsBadge)`
  font-size: ${style.vars.fontSize.small};
  margin-left: ${style.vars.whitespace.regular};
  padding: 7px 6px 5px 6px;
  min-width: 24px;
  max-height: 24px;
  background-color: ${style.colors.red.dark};
`;

const NavBadge = styled(Badge).attrs({ color: 'blue_dark' })`
  margin-left: ${style.vars.whitespace.small};
`;

const NotificationAndBadgeWrapper = styled.div`
  display: flex;
  align-self: start;
`;

const StyledIcon = styled(Icon)`
  transform: translateX(-5px);
  margin-right: ${style.vars.space['2']};
`;
interface MenuPanelProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  hasSubItemOpen?: boolean;
}

interface MenuContainerProps {
  isOpen?: boolean;
}

interface AvatarProps {
  src: string;
  avatarInitials: string;
  avatarColor: number;
  warning: boolean;
  uuid: string;
  id: string;
}

interface MenuProps {
  title?: string;
  className?: string;
  isOpen?: boolean;
  items: MobileMenuItemProps[];
  showPreviousCallback?: () => void;
  avatar?: React.ReactChild;
  dontRedirectOnContextSwitch?: boolean;
}

interface SubMenuAvatarProps {
  warning?: boolean;
  avatar?: AvatarProps;
}

interface SubMenuProps {
  title: string;
  items: MobileMenuItemProps[];
  warning?: boolean;
  avatar?: AvatarProps;
  dontRedirectOnContextSwitch?: boolean;
  hasBorders?: boolean;
}

export interface MobileMenuItemProps extends MenuItemProps {
  notificationsCount?: number;
  avatar?: AvatarProps;
  children?: MobileMenuItemProps[];
  suppressContext?: boolean;
  dontRedirectOnContextSwitch?: boolean;
  hasBorders?: boolean;
}

const MenuItem: React.FC<MobileMenuItemProps> = ({
  title,
  subtitle,
  icon,
  iconXlinkHrefPrefix,
  link = '',
  badgeLabel,
  notificationsCount,
  avatar,
  customIcon,
  ignoreCapitalization
}) => {
  return (
    <>
      {avatar && (
        <ListItem
          label={title}
          description={
            avatar.warning ? (
              <span>
                <StyledInlineAlertIcon icon="alert-warning-white-border"></StyledInlineAlertIcon>
                Requires sign-in update
              </span>
            ) : undefined
          }
          customIcon={
            <Box mt="-3px" mr="16px" mb="0" ml="0">
              <Avatar
                src={avatar.src}
                team={
                  'avatarInitials' in avatar
                    ? {
                        initials: avatar.avatarInitials,
                        color: avatar.avatarColor as 0
                      }
                    : undefined
                }
              />
            </Box>
          }
          isMobileNav
        ></ListItem>
      )}

      <a href={link}>
        <ListItem
          label={title}
          description={subtitle}
          icon={icon}
          iconXlinkHrefPrefix={iconXlinkHrefPrefix}
          customLabel={
            <NotificationAndBadgeWrapper>
              {notificationsCount > 0 && (
                <StyledNotificationsBadge count={notificationsCount} />
              )}
              {badgeLabel && <NavBadge>{badgeLabel}</NavBadge>}
            </NotificationAndBadgeWrapper>
          }
          customIcon={customIcon}
          isMobileNav
          capitalize={!ignoreCapitalization}
        ></ListItem>
      </a>
    </>
  );
};

const SubMenuAvatar: React.FC<SubMenuAvatarProps> = ({ warning, avatar }) => (
  <>
    {warning && (
      <StyledIcon icon="alert-warning-white-border" xlarge></StyledIcon>
    )}
    {!warning && avatar && (
      <Box mt="-3px" mr="16px" mb="0" ml="0">
        <Avatar
          src={avatar.src}
          team={
            'avatarInitials' in avatar
              ? {
                  initials: avatar.avatarInitials,
                  color: avatar.avatarColor as 0
                }
              : undefined
          }
        />
      </Box>
    )}
  </>
);

const SubMenu: React.FC<SubMenuProps> = ({
  title,
  items,
  warning,
  avatar,
  dontRedirectOnContextSwitch
}) => {
  // eslint-disable-next-line  @typescript-eslint/naming-convention
  const [subItemOpen, toggleSubItemOpen] = useToggle(false);

  return (
    <li>
      <ListItem
        label={title}
        onClick={() => toggleSubItemOpen(true)}
        customIcon={<SubMenuAvatar warning={warning} avatar={avatar} />}
        isNested
        isMobileNav
      ></ListItem>
      <MobileMenuItems
        key={`menu-${title}`}
        title={title}
        items={items}
        isOpen={subItemOpen}
        showPreviousCallback={() => toggleSubItemOpen(false)}
        dontRedirectOnContextSwitch={dontRedirectOnContextSwitch}
      />
    </li>
  );
};

const MobileMenuItems: React.FC<MenuProps> = ({
  title,
  isOpen = false,
  items,
  showPreviousCallback
}) => {
  return (
    <MenuItemsContainer isOpen={isOpen}>
      {(showPreviousCallback || title) && (
        <MenuHeader>
          {showPreviousCallback && (
            <PreviousButton
              data-testid="backButton"
              onClick={showPreviousCallback}
            >
              <IconButton icon="arrow-left" />
            </PreviousButton>
          )}
          {title && <MenuHeading data-testid="title">{title}</MenuHeading>}
        </MenuHeader>
      )}
      <MenuItemsWrapper>
        <MenuItems>
          {items.map((item) => {
            if (item.children) {
              return (
                <SubMenu
                  key={`item-${item.title}`}
                  title={item.title}
                  items={item.children}
                  warning={item.warning}
                  avatar={item.avatar}
                  hasBorders={item.hasBorders}
                />
              );
            } else {
              item.iconSize = 'xlarge';
              return (
                <MenuItem
                  key={`item-${item.title}-${
                    item.avatar?.avatarInitials || ''
                  }`}
                  title={item.title}
                  subtitle={item.subtitle}
                  link={item.link}
                  icon={item.icon}
                  iconSize={item.iconSize}
                  iconXlinkHrefPrefix={item.iconXlinkHrefPrefix}
                  badgeLabel={item.badgeLabel}
                  isExternal={item.isExternal}
                  notificationsCount={item.notificationsCount}
                  avatar={item.avatar}
                  suppressContext={item.suppressContext}
                  customIcon={item.customIcon}
                  ignoreCapitalization={item.ignoreCapitalization}
                />
              );
            }
          })}
        </MenuItems>
      </MenuItemsWrapper>
    </MenuItemsContainer>
  );
};

export default MobileMenuItems;
