function slugify(str: string): string {
  if (!str) {
    return '';
  }

  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

function slugToKey(slug: string): string {
  // todo: Add-Ons intentionally has a hyphen, so unfortunately we need to have special logic
  // we need to have a better way to generate slugs, and not convert them back and forth on the front-end.
  const isAddons = slug.toLowerCase() === 'add-ons';

  if (isAddons) {
    return 'Add-Ons';
  }

  return slug.replace(/-/g, ' ').replace(/\w\S*/g, (txt) => {
    if (txt === 'and') {
      return txt;
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export { slugify, slugToKey };
