import { FlexboxGrid, Text, style } from '@do/walrus';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import { NavigationItem } from '../../types';
import { MARKETPLACE_SITEMAP_URL } from '../../utils/constants';
import copy from '../copy';

const FooterContainer = styled.footer`
  flex-shrink: 0;
  background-color: #010e28;
  padding-bottom: 96px;
  padding-top: 96px;
  margin-top: 96px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;

  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  abbr {
    border-bottom: none;
    text-decoration: none;
  }

  li path {
    fill: currentColor;
  }
`;

const InnerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 28px;
`;

const FooterListHeader = styled.dt`
  color: white;
  margin-bottom: 1em;

  a {
    color: white;
  }
`;

const FooterListItem = styled.dd`
  margin-bottom: 0.5em;
`;

const SitemapLink = styled.a.attrs({
  href: MARKETPLACE_SITEMAP_URL,
  children: copy.sitemap
})`
  display: inline-block;
  margin-top: ${style.vars.space['2']};
`;

interface Props {
  categoryItems: NavigationItem[];
  useCaseItems: NavigationItem[];
  vendorItems: NavigationItem[];
  switchToItems: NavigationItem[];
}

const renderListItems = (items: NavigationItem[]) =>
  items.map((item, index) => (
    <FooterListItem key={index}>
      <Link href={item.link}>{item.title}</Link>
    </FooterListItem>
  ));

class Footer extends React.Component<Props> {
  public render() {
    const { categoryItems, useCaseItems, vendorItems, switchToItems } =
      this.props;

    return (
      <FooterContainer role="contentinfo">
        <InnerContainer>
          <FlexboxGrid>
            <FlexboxGrid.Col md={4} lg={4} className="do-u-mb--large">
              <a href="https://www.digitalocean.com" title="DigitalOcean">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                >
                  <g fill="#0080FF" fillRule="evenodd">
                    <path d="M24.9153 50v-9.661c10.226 0 18.1638-10.1413 14.2372-20.904-1.4406-3.983-4.6327-7.1751-8.6158-8.6158C19.774 6.921 9.6327 14.8305 9.6327 25.0565H0C0 8.7571 15.7627-3.9548 32.8531 1.3842c7.4576 2.3446 13.418 8.2768 15.7345 15.7344C53.9266 34.2373 41.2429 50 24.9153 50" />
                    <path d="M15.339 40.3672h9.6045v-9.6045H15.339zM7.9379 47.7684h7.401v-7.4012H7.938zM1.7514 40.3672H7.938v-6.1864H1.7514z" />
                  </g>
                </svg>
              </a>
              <Text inverted>
                &copy; {new Date().getFullYear()} DigitalOcean, LLC. All rights
                reserved.
                <br />
                <a href="https://nytech.org/made">
                  Proudly made in <abbr title="New York">NY</abbr>
                </a>
              </Text>
              <ul className="do-Inline do-u-mt--medium">
                <li>
                  <a
                    href="https://twitter.com/digitalocean"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={copy.socialLinks.twitter}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7.547 21.5c9.056 0 14.0092-7.5028 14.0092-14.0092 0-.2131 0-.4253-.0144-.6365a10.0178 10.0178 0 0 0 2.4565-2.5486 9.8278 9.8278 0 0 1-2.828.7747A4.9408 4.9408 0 0 0 23.335 2.357a9.8671 9.8671 0 0 1-3.1265 1.195c-1.5263-1.6228-3.9514-2.02-5.9156-.9688-1.9641 1.0512-2.9789 3.2893-2.4752 5.4594A13.9785 13.9785 0 0 1 1.6703 2.8985C.3635 5.1482 1.031 8.0263 3.1946 9.471A4.887 4.887 0 0 1 .96 8.8548v.0624c.0007 2.3438 1.6528 4.3625 3.9501 4.8266a4.9158 4.9158 0 0 1-2.2232.0844c.645 2.0057 2.4935 3.3797 4.6 3.4193a9.8796 9.8796 0 0 1-6.1147 2.1119A10.0226 10.0226 0 0 1 0 19.2884a13.9392 13.9392 0 0 0 7.547 2.2078" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/DigitalOceanCloudHosting"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={copy.socialLinks.facebook}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22.6753 0H1.3245C.5929 0 0 .593 0 1.3246v21.3508C0 23.4068.593 24 1.3245 24H12.819v-9.294H9.6913v-3.6221h3.1278V8.4128c0-3.1 1.8934-4.7879 4.6585-4.7879 1.3248 0 2.4631.0985 2.7949.1428v3.2395l-1.918.001c-1.504 0-1.795.7144-1.795 1.7631v2.3126h3.5866l-.4668 3.622h-3.1198V24h6.1158C23.4068 24 24 23.4068 24 22.6754V1.3246C24 .5929 23.4068 0 22.6753 0"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/thedigitalocean/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={copy.socialLinks.instagram}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fillRule="evenodd">
                        <path d="M11.9962.0076c-3.258 0-3.6665.0138-4.946.0722-1.2769.0582-2.149.261-2.912.5576-.7888.3065-1.4578.7167-2.1248 1.3836-.6669.667-1.077 1.336-1.3836 2.1248-.2966.763-.4994 1.6351-.5576 2.912C.0138 8.3373 0 8.7458 0 12.0038s.0138 3.6665.0722 4.946c.0582 1.2769.261 2.149.5576 2.912.3065.7888.7167 1.4578 1.3836 2.1248.667.6669 1.336 1.077 2.1248 1.3836.763.2966 1.6351.4994 2.912.5576 1.2795.0584 1.688.0722 4.946.0722s3.6665-.0138 4.946-.0722c1.2769-.0582 2.149-.261 2.912-.5576.7888-.3065 1.4578-.7167 2.1248-1.3836.6669-.667 1.0771-1.336 1.3837-2.1248.2965-.763.4993-1.6351.5575-2.912.0584-1.2795.0722-1.688.0722-4.946s-.0138-3.6665-.0722-4.946c-.0582-1.2769-.261-2.149-.5575-2.912-.3066-.7888-.7168-1.4578-1.3837-2.1248-.667-.6669-1.336-1.0771-2.1248-1.3836-.763-.2966-1.6351-.4994-2.912-.5576-1.2795-.0584-1.688-.0722-4.946-.0722zm0 2.1614c3.2031 0 3.5826.0123 4.8475.07 1.1696.0533 1.8048.2488 2.2275.413.56.2177.9596.4776 1.3794.8974.4198.4198.6797.8194.8974 1.3794.1642.4227.3597 1.0579.413 2.2275.0577 1.265.07 1.6444.07 4.8475s-.0123 3.5825-.07 4.8475c-.0533 1.1696-.2488 1.8048-.413 2.2275-.2177.56-.4776.9596-.8974 1.3793-.4198.4199-.8194.6798-1.3794.8974-.4227.1643-1.0579.3597-2.2275.413-1.2647.0578-1.6441.07-4.8475.07-3.2033 0-3.5827-.0122-4.8475-.07-1.1696-.0533-1.8048-.2487-2.2275-.413-.56-.2176-.9596-.4775-1.3793-.8974-.4198-.4197-.6798-.8193-.8974-1.3793-.1643-.4227-.3597-1.0579-.413-2.2275-.0578-1.265-.07-1.6444-.07-4.8475s.0122-3.5826.07-4.8475c.0533-1.1696.2487-1.8048.413-2.2275.2176-.56.4775-.9596.8974-1.3794.4197-.4198.8193-.6797 1.3793-.8974.4227-.1642 1.0579-.3597 2.2275-.413 1.265-.0577 1.6444-.07 4.8475-.07z" />
                        <path d="M11.9962 16.0025c-2.2084 0-3.9987-1.7903-3.9987-3.9987 0-2.2085 1.7903-3.9988 3.9987-3.9988 2.2085 0 3.9988 1.7903 3.9988 3.9988 0 2.2084-1.7903 3.9987-3.9988 3.9987zm0-10.159c-3.4022 0-6.1602 2.758-6.1602 6.1603 0 3.4022 2.758 6.1602 6.1602 6.1602 3.4022 0 6.1602-2.758 6.1602-6.1602 0-3.4022-2.758-6.1602-6.1602-6.1602zm7.8432-.2433c0 .795-.6445 1.4395-1.4396 1.4395-.795 0-1.4395-.6445-1.4395-1.4395s.6445-1.4396 1.4395-1.4396 1.4396.6445 1.4396 1.4396" />
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/user/DigitalOceanVideos"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={copy.socialLinks.youtube}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.3885 6.6162c.5115 1.8687.4918 5.7635.4918 5.7635s0 3.8752-.4918 5.744c-.2754 1.0228-1.0819 1.8293-2.1047 2.1047-1.8688.4918-9.3436.4918-9.3436.4918s-7.4553 0-9.3437-.5115C1.5737 19.9333.7672 19.1268.4918 18.104 0 16.255 0 12.3601 0 12.3601s0-3.8752.4918-5.7439c.2754-1.0229 1.1015-1.849 2.1047-2.1244C4.4653 4 11.9402 4 11.9402 4s7.4748 0 9.3436.5114c1.0228.2754 1.8293 1.082 2.1047 2.1048zM9.56 15.9402l6.216-3.5801L9.56 8.78v7.1602z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/digitalocean"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={copy.socialLinks.linkedin}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22.2236 0c.9786 0 1.7777.7751 1.7777 1.7293v20.5391c0 .9556-.799 1.7316-1.7777 1.7316H1.7716C.7938 24 0 23.224 0 22.2684V1.7294C0 .775.7938 0 1.7716 0h20.452zm-1.773 20.4511V14.169c0-3.0849-.6653-5.4565-4.2675-5.4565-1.7315 0-2.8933.9498-3.3684 1.8503h-.0485v-1.565h-3.415v11.4534h3.5586v-5.6653c0-1.4947.2826-2.9414 2.135-2.9414 1.824 0 1.8503 1.7085 1.8503 3.037v5.5697h3.5556zM5.3383 7.432c1.1378 0 2.0627-.9249 2.0627-2.0644 0-1.1383-.9249-2.0636-2.0627-2.0636-1.1413 0-2.0635.9253-2.0635 2.0636 0 1.1395.9222 2.0644 2.0635 2.0644zM3.5573 20.4507h3.5614V8.9977H3.5573v11.453z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.glassdoor.com/Overview/Working-at-DigitalOcean-EI_IE823482.11,23.htm"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={copy.socialLinks.glassdoor}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.7143 20.5715H3C3 22.465 4.535 24 6.4286 24h10.2857c1.8935 0 3.4286-1.535 3.4286-3.4285V6.4923a.125.125 0 0 0-.125-.1249h-3.1787a.125.125 0 0 0-.125.1249v14.0792zm0-20.5715c1.8935 0 3.4286 1.535 3.4286 3.4286H6.4286v14.0792a.125.125 0 0 1-.1249.125H3.125A.125.125 0 0 1 3 17.5077V3.4286C3 1.5351 4.535 0 6.4286 0h10.2857z"
                      />
                    </svg>
                  </a>
                </li>
              </ul>

              <SitemapLink />
            </FlexboxGrid.Col>
            <FlexboxGrid.Col md={2} lg={2}>
              <dl data-testid="categories-links">
                <FooterListHeader>
                  {copy.navigation.categories}
                </FooterListHeader>
                {renderListItems(categoryItems)}
              </dl>
            </FlexboxGrid.Col>
            <FlexboxGrid.Col md={2} lg={2}>
              {useCaseItems && (
                <dl data-testid="use-cases-links">
                  <FooterListHeader>
                    {copy.navigation.useCases}
                  </FooterListHeader>
                  {renderListItems(useCaseItems)}
                </dl>
              )}
            </FlexboxGrid.Col>
            <FlexboxGrid.Col md={2} lg={2}>
              <dl data-testid="for-vendors-links">
                <FooterListHeader>
                  {copy.navigation.forVendors}
                </FooterListHeader>
                {renderListItems(vendorItems)}
              </dl>
            </FlexboxGrid.Col>
            <FlexboxGrid.Col md={2} lg={2}>
              <dl data-testid="switch-to-links">
                <FooterListHeader>{copy.navigation.switchTo}</FooterListHeader>
                {renderListItems(switchToItems)}
              </dl>
            </FlexboxGrid.Col>
          </FlexboxGrid>
        </InnerContainer>
      </FooterContainer>
    );
  }
}

export default Footer;
