import { style } from '@do/walrus';
import getConfig from 'next/config';
import React, { FC } from 'react';
import styled from 'styled-components';

const { publicRuntimeConfig } = getConfig();
const { APP_ENV } = publicRuntimeConfig;

interface Props {
  preview?: boolean;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: ${style.colors.orange.lightest};
  border: 3px solid ${style.colors.orange.light};
  border-left: none;
  border-bottom: none;
  padding: ${style.vars.space['4']};
  border-top-right-radius: ${style.vars.borderRadius.base};
`;

const PreviewAlert: FC<Props> = ({ preview = false }) => {
  if (APP_ENV === 'production' || !preview) {
    return null;
  }

  return (
    <Container>
      This is page is a preview.
      <br />
      <a href="/api/exit-preview">Click here</a> to exit preview mode.
    </Container>
  );
};

export default PreviewAlert;
